<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('read')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_permisos_edit', params: $router.params }">
                    <v-icon class="mr-2">edit</v-icon>
                    <span class="subtitle-2">Editar</span>
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <DashCard title="General">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Elaboración</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly :value="item.fecha_creacion | moment('L')" prepend-inner-icon="event" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Area</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly :value="area.nombre" prepend-inner-icon="room" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Dueños</v-label>
                            <v-list>
                                <v-list-item class="px-0" v-for="(prop, i) in item.propietarios" :key="i">
                                    <v-list-item-icon class="mx-2">
                                        <v-icon x-large color="secondary">account_box</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ prop.persona }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ prop.gerencia ? prop.gerencia.nombre : "Sin información" }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12">
                            <v-label>Estado</v-label>
                            <v-flex>
                                <v-chip class="ma-1" dark large label :color="item.estado | color_estado('permiso')">
                                    <v-icon class="pr-2">flag</v-icon>
                                    <span>{{ item.estado | estado('permiso') }}</span>
                                </v-chip>
                                <v-chip dark large label :color="item.estado_tiempo | color_estado('tiempo')">
                                    <v-icon large>av_timer</v-icon>
                                    <span class="px-2">{{ item.estado_tiempo | estado('tiempo') }}</span>
                                </v-chip>
                            </v-flex>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!-- descripción -->
            <v-col cols="12" md="6" lg="8">
                <DashCard title="Descripción">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Título</v-label>
                            <v-textarea hide-details dense solo outlined flat readonly v-model="item.titulo" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Coordinador encargado</v-label>
                            <PersonaListItem v-model="item.persona_autor" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Jefe del coordinador</v-label>
                            <PersonaListItem v-model="item.jefe_autor" />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!-- descripción -->
            <v-col cols="12">
                <DashCard title="Tareas" body-class="pa-0">
                    <GestorTareas
                        v-model="item.tareas" 
                        model-type="permisos" 
                        @tarea-change="reload" />
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import GestorTareas from '@/dash/components/GestorTareas'
import AccessMessage from '@/dash/components/AccessMessage'

import { axiosHelper } from '@/helpers'

export default {
    components: {
        DashCard,
        PersonaListItem,
        GestorTareas,
        AccessMessage
    },
    data: () => ({
        item: {},
        title: {
            text: "Permiso Crítico",
            icon: "inbox"
        }
    }),
    computed: {
        area() {
            return this.item.area || {};
        }
    },
    methods: {
        reload() {
            this.$parent.$parent.reloadComponent();
        }
    },
    created() {
        this.$emit('title', this.title);

        if(!this.$parent.$parent.get_permission('read')) return;
    
        axiosHelper.get('permisos/' + this.$route.params.id + '/')
            .then(response => {
                this.item = response.data;
                this.title.text = "Permiso Crítico Nº" + this.item.id;
            });
    }
}
</script>